@keyframes Skeleton_SkeletonAnimation__148dde60 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.Skeleton_Bone__148dde61 {
  background-color: var(--seed-scale-color-gray-500);
  opacity: 0.15;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
          mask-image: -webkit-radial-gradient(white, black);
}
.Skeleton_Bone__148dde61:after {
  content: "";
  display: block;
  background: linear-gradient(
        to left, 
        var(--seed-scale-color-gray-500),
        var(--seed-scale-color-gray-400) 40%,
        var(--seed-scale-color-gray-400) 60%,
        var(--seed-scale-color-gray-500)
      );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  animation: 2s Skeleton_SkeletonAnimation__148dde60 ease-in-out infinite;
  min-width: 10rem;
}