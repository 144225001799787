:root[data-seed] {
  --seed-slider-track-color: var(--seed-scale-color-gray-200);
  --seed-slider-range-color: var(--seed-semantic-color-primary);
  --seed-slider-thumb-color: var(--seed-static-color-static-white);

  --seed-slider-range-disabled-color: var(--seed-scale-color-gray-400);

  --seed-slider-tick-color: var(--seed-scale-color-gray-00);

  --seed-slider-marker-color: var(--seed-scale-color-gray-900);
  --seed-slider-marker-disabled-color: var(--seed-scale-color-gray-400);

  /* Raw Values */
  --seed-slider-thumb-size: 28px;
  --seed-slider-thumb-border-radius: 9999px;
  --seed-slider-track-border-radius: 9999px;
  --seed-slider-track-height: 5px;

  --seed-slider-min-width: 240px;
  --seed-slider-min-height: 240px;

  --seed-slider-thumb-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
