.SearchInputNavbar_Container__nfgw4w0 {
  display: flex;
  width: 100%;
  background-color: var(--seed-semantic-color-paper-default);
}
.SearchInputNavbar_PageBackContainer__nfgw4w1 {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
.SearchInputNavbar_InputBox__nfgw4w2 {
  display: flex;
  background-color: var(--seed-scale-color-gray-100);
  padding: 0 1.75rem 0 0.75rem;
  border-radius: 0.375rem;
  transition: background-color 300ms;
}
.SearchInputNavbar_InputBox__nfgw4w2:active {
  background-color: var(--seed-scale-color-gray-200);
  transition: background-color 0ms;
}
.SearchInputNavbar_InputCancel__nfgw4w3 {
  display: flex;
  position: absolute;
  width: 1.125rem;
  height: 1.125rem;
  padding: 0.225rem;
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 300ms;
}
.SearchInputNavbar_InputCancel__nfgw4w3:active {
  opacity: 0.2;
  transition: opacity 0s;
}
.SearchInputNavbar_InputCancel_platform_Android__nfgw4w4 {
  top: 50%;
  right: 1.55rem;
}
.SearchInputNavbar_InputCancel_platform_Cupertino__nfgw4w5 {
  top: 45%;
  right: 1.4rem;
}
.SearchInputNavbar_InputCancelTreatment__nfgw4w6 {
  display: flex;
  position: absolute;
  width: 1.125rem;
  height: 1.125rem;
  padding: 0.225rem;
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 300ms;
}
.SearchInputNavbar_InputCancelTreatment__nfgw4w6:active {
  opacity: 0.2;
  transition: opacity 0s;
}
.SearchInputNavbar_InputCancelTreatment_platform_Android__nfgw4w7 {
  top: 50%;
  right: 1rem;
}
.SearchInputNavbar_InputCancelTreatment_platform_Cupertino__nfgw4w8 {
  top: 45%;
  right: 1rem;
}
.SearchInputNavbar_Input__nfgw4w9 {
  width: 100%;
  font-size: 1rem;
  line-height: 1.375rem;
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  background: none;
  outline: none;
  color: var(--seed-scale-color-gray-900);
  -webkit-tap-highlight-color: transparent;
  caret-color: #458FFF;
}
.SearchInputNavbar_Input__nfgw4w9::placeholder {
  color: var(--seed-scale-color-gray-500);
}
.SearchInputNavbar_Input__nfgw4w9::-webkit-search-decoration {
  display: none;
}
.SearchInputNavbar_Input__nfgw4w9::-webkit-search-cancel-button {
  display: none;
}
.SearchInputNavbar_Input__nfgw4w9::-webkit-search-results-button {
  display: none;
}
.SearchInputNavbar_Input__nfgw4w9::-webkit-search-results-decoration {
  display: none;
}
.SearchInputNavbar_Input_platform_Android__nfgw4wa {
  padding: 0.563rem 0.5rem 0.563rem 0;
}
.SearchInputNavbar_Input_platform_Cupertino__nfgw4wb {
  padding: 0.438rem 0.5rem 0.438rem 0;
}
.SearchInputNavbar_InputArea__nfgw4wc {
  flex: 1;
  position: relative;
}
.SearchInputNavbar_InputArea_platform_Android__nfgw4wd {
  padding: 0.5rem 1rem 0.5rem 0.325rem;
}
.SearchInputNavbar_InputArea_platform_Cupertino__nfgw4we {
  padding: 0.125rem 1rem 0.344rem 0.25rem;
}
.SearchInputNavbar_InputAreaTreatment__nfgw4wf {
  flex: 1;
  position: relative;
}
.SearchInputNavbar_InputAreaTreatment_platform_Android__nfgw4wg {
  padding: 0.5rem 0.5rem 0.5rem 0.325rem;
}
.SearchInputNavbar_InputAreaTreatment_platform_Cupertino__nfgw4wh {
  padding: 0.125rem 0.5rem 0.344rem 0.25rem;
}
.SearchInputNavbar_BackIconContainer__nfgw4wi {
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding: 0.375rem;
  border-radius: 50%;
  color: var(--seed-scale-color-gray-900);
}
.SearchInputNavbar_BackIconContainer_platform_Android__nfgw4wj {
  transition: background-color 300ms;
}
.SearchInputNavbar_BackIconContainer_platform_Android__nfgw4wj:active {
  opacity: 0.2;
  transition: opacity 0s;
}
.SearchInputNavbar_BackIconContainer_platform_Cupertino__nfgw4wk {
  transition: opacity 300ms;
}
.SearchInputNavbar_BackIconContainer_platform_Cupertino__nfgw4wk:active {
  background-color: var(--seed-scale-color-gray-500);
  transition: background-color 0s;
}
.SearchInputNavbar_CloseButtonContainer__nfgw4wl {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  transition: background-color 300ms;
}
.SearchInputNavbar_CloseButtonContainer__nfgw4wl:active {
  opacity: 0.2;
  transition: opacity 0s;
}
.SearchInputNavbar_CloseButton__nfgw4wm {
  font-family: inherit;
  border: none;
  background: none;
  -webkit-appearance: none;
          appearance: none;
  font-size: var(--seed-semantic-typography-label2-regular-font-size);
  font-weight: var(--seed-semantic-typography-label2-regular-font-weight);
  line-height: var(--seed-semantic-typography-label2-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label2-regular-letter-spacing);
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  color: var(--seed-scale-color-gray-900);
}
.SearchInputNavbar_CloseButton_platform_Android__nfgw4wn {
  padding: 0.5rem 1rem 0.5rem 0.25rem;
}
.SearchInputNavbar_CloseButton_platform_Cupertino__nfgw4wo {
  padding: 0.125rem 1rem 0.344rem 0.25rem;
}