.CardFleaMarket_container__1yrlisz0 {
  display: flex;
  color: var(--seed-scale-color-gray-900);
}
.CardFleaMarket_imageContainer__1yrlisz1 {
  width: 6.75rem;
  height: 6.75rem;
  border: 1px solid var(--seed-scale-color-gray-alpha-50);
  background-color: var(--seed-semantic-color-paper-background);
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.375rem;
  flex: 0 0 auto;
}
.CardFleaMarket_imageContainer__1yrlisz1 .CardFleaMarket_image__1yrlisz2 {
  width: 6.75rem;
  height: 6.75rem;
  object-fit: cover;
  border-radius: 0.31rem;
}
.CardFleaMarket_image__1yrlisz2[src=""] {
  display: none;
}
.CardFleaMarket_main__1yrlisz3 {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  min-width: 0;
}
.CardFleaMarket_title__1yrlisz4 {
  font-size: var(--seed-semantic-typography-body-m1-regular-font-size);
  font-weight: var(--seed-semantic-typography-body-m1-regular-font-weight);
  line-height: var(--seed-semantic-typography-body-m1-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-body-m1-regular-letter-spacing);
  margin-bottom: 0.0625rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  visibility: visible;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.CardFleaMarket_status__1yrlisz5 {
  display: flex;
  margin-top: 0.125rem;
}
.CardFleaMarket_statusGap__1yrlisz6 {
  margin-right: 0.25rem;
}
.CardFleaMarket_freeSharing__1yrlisz7 {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: bold;
}
.CardFleaMarket_price__1yrlisz8 {
  font-size: 0.875rem;
  font-weight: bold;
}
.CardFleaMarket_captions__1yrlisz9 {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}
.CardFleaMarket_caption__1yrlisza {
  color: var(--seed-scale-color-gray-600);
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  line-height: 100%;
}
.CardFleaMarket_caption__1yrlisza:last-child {
  margin-right: 0;
}
.CardFleaMarket_captionIcon__1yrliszb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.125rem;
  color: var(--seed-scale-color-gray-400);
}