.Badge_badge__1tfzhqs0 {
  display: flex;
  width: -webkit-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
}
.Badge_badge_type_normal__1tfzhqs6 {
  font-weight: var(--seed-static-font-weight-regular);
  border: none;
}
.Badge_badge_type_outline__1tfzhqs7 {
  font-weight: var(--seed-static-font-weight-bold);
  border-width: 1px;
  border-style: solid;
}
.Badge_badge_type_bold__1tfzhqs8 {
  font-weight: var(--seed-static-font-weight-bold);
  border: none;
}
.Badge_badge_size_large__1tfzhqs9 {
  padding: 4px 8px;
  font-size: var(--seed-semantic-typography-label4-regular-font-size);
  line-height: var(--seed-semantic-typography-label4-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label4-regular-letter-spacing);
}
.Badge_badge_size_medium__1tfzhqsa {
  padding: 2px 6px;
  font-size: var(--seed-semantic-typography-label5-regular-font-size);
  line-height: var(--seed-semantic-typography-label5-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label5-regular-letter-spacing);
}
.Badge_badge_size_small__1tfzhqsb {
  padding: 2px 4px;
  font-size: var(--seed-semantic-typography-label6-regular-font-size);
  line-height: var(--seed-semantic-typography-label6-regular-line-height);
  letter-spacing: var(--seed-semantic-typography-label6-regular-letter-spacing);
}
.Badge_badge_shape_pill__1tfzhqsd {
  border-radius: 1000px;
}
.Badge_badge_compound_0__1tfzhqse {
  color: var(--seed-scale-color-gray-700);
  background: var(--seed-scale-color-gray-100);
}
.Badge_badge_compound_1__1tfzhqsf {
  color: var(--seed-scale-color-gray-900);
  border-color: var(--seed-scale-color-gray-900);
}
.Badge_badge_compound_2__1tfzhqsg {
  color: var(--seed-scale-color-gray-00);
  background: var(--seed-scale-color-gray-700);
}
.Badge_badge_compound_3__1tfzhqsh {
  color: var(--seed-semantic-color-primary);
  background: var(--seed-scale-color-carrot-alpha-100);
}
.Badge_badge_compound_4__1tfzhqsi {
  color: var(--seed-semantic-color-primary);
  border-color: var(--seed-semantic-color-primary);
}
.Badge_badge_compound_5__1tfzhqsj {
  color: var(--seed-static-color-static-white);
  background: var(--seed-semantic-color-primary);
}
.Badge_badge_compound_6__1tfzhqsk {
  color: var(--seed-scale-color-blue-800);
  background: var(--seed-scale-color-blue-50);
}
.Badge_badge_compound_7__1tfzhqsl {
  color: var(--seed-scale-color-blue-700);
  border-color: var(--seed-scale-color-blue-700);
}
.Badge_badge_compound_8__1tfzhqsm {
  color: var(--seed-static-color-static-white);
  background: var(--seed-semantic-color-accent);
}
.Badge_badge_compound_9__1tfzhqsn {
  color: var(--seed-scale-color-green-700);
  background: var(--seed-semantic-color-success-low);
}
.Badge_badge_compound_10__1tfzhqso {
  color: var(--seed-semantic-color-success);
  border-color: var(--seed-semantic-color-success);
}
.Badge_badge_compound_11__1tfzhqsp {
  color: var(--seed-static-color-static-white);
  background: var(--seed-semantic-color-success);
}
.Badge_badge_compound_12__1tfzhqsq {
  color: var(--seed-semantic-color-danger);
  background: var(--seed-semantic-color-danger-low);
}
.Badge_badge_compound_13__1tfzhqsr {
  color: var(--seed-semantic-color-danger);
  border-color: var(--seed-semantic-color-danger);
}
.Badge_badge_compound_14__1tfzhqss {
  color: var(--seed-static-color-static-white);
  background: var(--seed-semantic-color-danger);
}
.Badge_badge_compound_15__1tfzhqst {
  border-radius: 4px;
}
.Badge_badge_compound_16__1tfzhqsu {
  border-radius: 2px;
}
.Badge_badge_compound_17__1tfzhqsv {
  border-radius: 2px;
}