.CardBizPost_titleContainer__jh11lo0 {
  display: flex;
  align-items: center;
}
.CardBizPost_regular__jh11lo1 {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  background: var(--seed-scale-color-gray-100);
  border-radius: 0.1875rem;
  margin-right: 0.25rem;
  width: 1.875rem;
  height: 1.375rem;
}
.CardBizPost_coupon__jh11lo2 {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.3125rem 0 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 1.375rem;
  opacity: 0.9;
  font-size: 0.75rem;
  background: var(--seed-semantic-color-primary);
  color: var(--seed-static-color-static-white);
}
.CardBizPost_container__jh11lo3 {
  display: flex;
  color: var(--seed-scale-color-gray-900);
}
.CardBizPost_imageContainer__jh11lo4 {
  flex: 0 0 auto;
  width: 6.75rem;
  height: 6.75rem;
  background-color: var(--seed-semantic-color-paper-background);
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.375rem;
}
.CardBizPost_imageContainer__jh11lo4 .CardBizPost_image__jh11lo5 {
  width: 6.75rem;
  height: 6.75rem;
  object-fit: cover;
  border-radius: 0.375rem;
}
.CardBizPost_image__jh11lo5[src=""] {
  display: none;
}
.CardBizPost_main__jh11lo6 {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  min-width: 0;
}
.CardBizPost_content__jh11lo7 {
  font-size: 0.875rem;
  margin-top: 0.125rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  visibility: visible;
  color: var(--seed-scale-color-gray-700);
}
.CardBizPost_title__jh11lo8 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  font-size: 1rem;
  line-height: 140%;
  color: var(--seed-scale-color-gray-900);
}
.CardBizPost_profileImageContainer__jh11lo9 {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.625rem;
  margin-right: 0.25rem;
  overflow: hidden;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
}
.CardBizPost_profileImageContainer__jh11lo9 .CardBizPost_profileImage__jh11loa {
  display: flex;
  width: 0.875rem;
  height: 0.875rem;
  object-fit: cover;
  border-radius: 0.625rem;
}
.CardBizPost_profileImage__jh11loa[src=""] {
  display: none;
}
.CardBizPost_caption__jh11lob {
  color: var(--seed-scale-color-gray-600);
  font-size: 0.813rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  line-height: 150%;
  height: 1.25rem;
}
.CardBizPost_caption__jh11lob:last-child {
  margin-right: 0;
}
.CardBizPost_caption__jh11lob:first-of-type {
  margin-left: auto;
}
.CardBizPost_captions__jh11loc {
  display: flex;
  margin-top: auto;
}
.CardBizPost_captionIcon__jh11lod {
  display: flex;
  justify-content: center;
  width: 0.9375rem;
  height: 0.9375rem;
  align-items: center;
  margin-right: 0.125rem;
  color: var(--seed-scale-color-gray-400);
}
.CardBizPost_subContentContainer__jh11loe {
  margin-top: 0.25rem;
}