.AutoCompleteKeyword_container__t3w8uo0 {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 300ms;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
}
.AutoCompleteKeyword_container__t3w8uo0:active {
  background-color: var(--seed-scale-color-gray-100);
  transition: background-color 0s;
}
.AutoCompleteKeyword_labelContainer__t3w8uo1 {
  display: flex;
  align-items: baseline;
  margin-left: 0.75rem;
}
.AutoCompleteKeyword_autoCompletionLabel__t3w8uo2 {
  padding: 0.594rem 0;
  white-space: nowrap;
  font-size: 0.875rem;
  color: var(--seed-scale-color-gray-900);
}
.AutoCompleteKeyword_autoCompletionCategory__t3w8uo3 {
  font-size: 0.813rem;
  margin-left: 0.5rem;
  color: var(--seed-scale-color-gray-600);
}
.AutoCompleteKeyword_highlight__t3w8uo4 {
  background-color: inherit;
  text-decoration: none;
  font-style: normal;
  color: var(--seed-scale-color-carrot-500);
}