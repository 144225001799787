/* Root */
.seed-slider[data-part="root"] {
  display: inline-flex;
}

.seed-slider[data-part="root"][data-orientation="horizontal"] {
  width: 100%;
  flex-direction: column;
}

.seed-slider[data-part="root"][data-orientation="vertical"] {
  height: 100%;
  flex-direction: row;
}

/* Control */
.seed-slider [data-part="control"] {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.seed-slider [data-part="control"][data-orientation="horizontal"] {
  flex-direction: row;
  height: var(--seed-slider-thumb-size);
  min-width: var(--seed-slider-min-width);
  width: 100%;
}

.seed-slider [data-part="control"][data-orientation="vertical"] {
  flex-direction: column;
  width: var(--seed-slider-thumb-size);
  min-height: var(--seed-slider-min-height);
  height: 100%;
}

/* Track */
.seed-slider [data-part="track"] {
  border-radius: var(--seed-slider-track-border-radius);
  background: var(--seed-slider-track-color);
  flex: 1;
}

.seed-slider [data-part="track"][data-orientation="horizontal"] {
  height: var(--seed-slider-track-height);
}

.seed-slider [data-part="track"][data-orientation="vertical"] {
  width: var(--seed-slider-track-height);
}

/* Range */
.seed-slider [data-part="range"] {
  border-radius: inherit;
  background: var(--seed-slider-range-color);
}

.seed-slider [data-part="range"][data-disabled] {
  background: var(--seed-slider-range-disabled-color);
}

.seed-slider [data-part="range"][data-orientation="vertical"] {
  width: 100%;
}

.seed-slider [data-part="range"][data-orientation="horizontal"] {
  height: 100%;
}

/* Thumb */
.seed-slider [data-part="thumb"] {
  all: unset;
  width: var(--seed-slider-thumb-size);
  height: var(--seed-slider-thumb-size);
  border-radius: var(--seed-slider-thumb-border-radius);
  background: var(--seed-slider-thumb-color);
  box-shadow: var(--seed-slider-thumb-shadow);
}

/* Marker Group */
.seed-slider [data-part="marker-group"][data-orientation="horizontal"] {
  height: 20px;
}

.seed-slider [data-part="marker-group"][data-orientation="vertical"] {
  width: 20px;
}

/* Marker */
.seed-slider [data-part="marker"] {
  color: var(--seed-slider-marker-color);
  white-space: nowrap;
}

.seed-slider [data-part="marker"][data-disabled] {
  color: var(--seed-slider-marker-disabled-color);
}

.seed-slider[data-orientation="horizontal"]
  [data-part="marker"][data-align="start"] {
  transform: translateX(0%);
}

.seed-slider[data-orientation="horizontal"]
  [data-part="marker"][data-align="center"] {
  transform: translateX(-50%);
}

.seed-slider[data-orientation="horizontal"]
  [data-part="marker"][data-align="end"] {
  transform: translateX(-100%);
}

.seed-slider[data-orientation="vertical"]
  [data-part="marker"][data-align="start"] {
  transform: translateY(0%);
}

.seed-slider[data-orientation="vertical"]
  [data-part="marker"][data-align="center"] {
  transform: translateY(50%);
}

.seed-slider[data-orientation="vertical"]
  [data-part="marker"][data-align="end"] {
  transform: translateY(100%);
}

/* Marker Tick */
.seed-slider [data-part="tick"] {
  pointer-events: none;
  position: absolute;

  background: var(--seed-slider-tick-color);
}

.seed-slider[data-orientation="horizontal"] [data-part="tick"] {
  height: 100%;
  width: 1px;
}

.seed-slider[data-orientation="vertical"] [data-part="tick"] {
  height: 1px;
  width: 100%;
}
